<template>
  <div >
    <div class="SaleTop" >
      <img :src="total.cover" alt="" class="bgimg" />
      <div class="content">
        <div style="font-size: 24px; color: white; font-weight: 800">
          {{ total.title }}
        </div>
        <div class="content-flex">
          <div>{{ total.description }}</div>
        </div>
      </div>
    </div>
    <div class="sales-area" v-if="widthheight">
      <div class="wrap-pos"></div>
      <div class="product-box">
        <list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="loadData"
        >
          <div class="content-hot __flex __flex_wrap" v-if="product">
            <ProductCard
              :style="widthheight"
              v-for="(item, index) in product"
              :key="index"
              :contentWidth="contentWidth"
              :product="item"
              :isVipMember="isVipMember"
            />
          </div>
        </list>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Ref } from "vue-property-decorator";
import { List } from "vant";
import CapStoreApi from "@/api/cap-store.api";
import ProductCard from "@/views/SpecialSale/components/ProductCardsMember.vue";
import MemberApi from "@/api/member.api";
@Component({
  components: {
    ProductCard,
    List,
  },
})
export default class specialSaleMember extends Vue {
  widthheight = {};
  contentWidth = {};
  wideHigh = 0;
  page = 0;
  id = 0;
  page_size = 30;
  loading = false;
  finished = false;
  product = [];
  widthheight = {};
  contentWidth = {};
  wideHigh = 0;
  total = {};
  get activeHeight() {
    return `height:${this.product?.length/2*(this.wideHigh+120)+200}px`
  }
  async loadData() {
    this.page++;
    const { id, page, page_size, product } = this;
    let rows = await CapStoreApi.getHotItemList(id, page, page_size).catch(
      () => []
    );
    this.total = rows;
    this.product = rows.list;
    this.loading = false; //请求
    if (!rows.list.length || rows.list.length < this.page_size)
      this.finished = true;
    this.product = page > 1 ? [...product, ...rows.list] : rows.list;

  }
  isVipMember = false;
  async created() {
    this.id = this.$route.params.id;
    const { clientWidth, offsetWidth } = document.body;
    this.wideHigh = (clientWidth || offsetWidth) / 2 - 16;
    this.contentWidth = {
      width: this.wideHigh + "px",
      height: this.wideHigh + "px",
    };
    this.widthheight = {
      width: this.wideHigh + "px",
    };
    const { status } = await MemberApi.checkMemberIs();
    if (status == 1) {
      this.isVipMember = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.product-box {
  min-height: 100vh;
  .product-list {
    padding: 8px 10px;

    .product-item {
      width: calc(100% / 2 - 6px);
      margin-right: 12px;
      margin-bottom: 12px;

      &:nth-child(2n - 2) {
        margin-right: unset;
      }
    }
  }
}
.content-hot {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  padding-top: 24px;
  margin-top: -10px;
  position: relative;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
}
.SaleTop {
  position: relative;
  height: 138px;
}
.bgimg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
}
.content {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.45) 0%, #000000 100%);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 12px;
  .content-flex {
    display: flex;
    align-items: center;
    color: gray;
    font-size: 12px;
    margin-top: 8px;
  }
}
.sales-area {
  margin-top: -14px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background: #f2f2f2;
  position: relative;
  z-index: 999;
}
.wrap-pos {
  position: absolute;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 160px;
  width: 100%;
  background: linear-gradient(180deg, #ffffff 0%, #f2f2f2 100%);
}
</style>